import Log from "../../utils/log";
// INFO: 合约变量
const isProd = process.env.VUE_APP_CONTRACT_ENV == "production";

// * BSC测试网
// HIGH: TEST Net Contract
const DEV = {
    ENV: "DEV",
    // OXCTokenAddress: '0xc170524d0F3E787685000e99B05bF64b33CB8F4D',
    // InvestOXCAddress: '0x60F7320B21e3Ce58eF897285bDBea1D4E5331072',
    // UsdtAddress: '0x7EA8Eaf3ECD615eda2d2cb1D6fcc5d072e6d87Ff',
    // OxcIDOAddress: '0xB417f4C115257A6A3FfB77130f0a7Da45045D3eB',
    AITokenAddress: "0x9e8371aE24D1d7506Dc3046817c517a568704443",
    InvestAIAddress: "0xe0D4eEf70191384E31FA1EE40fdc30FA854197c0",
    UsdtAddress: "0xEA4d0d16dD3543234150aFd555127db462DbB682",
    DaoAddress: "0xE11C92a5C403B7F751df4ADeEB23D8aF1C2975F9",
    AIIEOAddress: "0x0026E986120d7C2a50cDB3f6eb0b7d1041fB9524",
    AIICOAddress: "0x94e7497D7AbEF48C96aA1c6e64bCB9891dC40209",
    DefaultUserAddress: "0x18D8003a3773c152CedF35B61A513AabE52F8b27",
};

// * BSC 主网
// HIGH: Main Net Contract
const MAIN = {
    ENV: "MAIN",
    AITokenAddress: "0x517F03AA4E1487e0B4eab77a97A332725D22096f",
    InvestAIAddress: "0x5E5D0A1C06371F57407C9cA93A03C3Abbeb7aA30",
    UsdtAddress: "0x55d398326f99059fF775485246999027B3197955",
    DaoAddress: "0xf9c57fdf310ca6a5c34b15385cd8b18207ff7a74",
    AIIEOAddress: "0x456BD436aB9Cce424e8BeB947964f903913e36F5",
    AIICOAddress: "0x4052E6D2111d4AF436A163bEb8049692C19c3829",
    DefaultUserAddress: "0x18D8003a3773c152CedF35B61A513AabE52F8b27",
};

// if (!isProd) {
//     console.log("=== ContractAddress ENV ===", isProd, MAIN);
// }
const CONFIG = isProd ? MAIN : DEV;

Log.log("=== ContractAddress ENV ===", CONFIG);

export default CONFIG;

// usdtAddress=> 0xEA4d0d16dD3543234150aFd555127db462DbB682
// aiAddress=> 0xE11C92a5C403B7F751df4ADeEB23D8aF1C2975F9

// AIT token address=> 0x9e8371aE24D1d7506Dc3046817c517a568704443
// aitIDO1-1 address=> 0x0026E986120d7C2a50cDB3f6eb0b7d1041fB9524
// aitIDO2-2 address=> 0x94e7497D7AbEF48C96aA1c6e64bCB9891dC40209
// investAIT address=> 0xe0D4eEf70191384E31FA1EE40fdc30FA854197c0

//main net
// AI token address=>0xf9c57fdf310ca6a5c34b15385cd8b18207ff7a74

// AIT token address=> 0x517F03AA4E1487e0B4eab77a97A332725D22096f

// investAIT address=> 0x5E5D0A1C06371F57407C9cA93A03C3Abbeb7aA30

// aitIDO1-1 address=> 0x456BD436aB9Cce424e8BeB947964f903913e36F5

// aitIDO2-2 address=> 0x4052E6D2111d4AF436A163bEb8049692C19c3829
